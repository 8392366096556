/* -------- */
/* REGISTER */
/* -------- */

div#register.card {
    >div {
        >div {

            >div {

                /* CARD HEADER */
                &:first-of-type {

                    /* LOGO */
                    >div.container-logo {
                        margin: 1rem;
                        text-align: center;

                        >a {
                            >img {
                                width: 14rem;
                            }
                        }
                    }
                }

                /* FORMULAIRE */
                &:last-of-type {

                    >div.alert-success,
                    >div.alert-warning,
                    >div.alert-danger {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.475s;

                        >ul {
                            list-style: none;
                            margin: 0 auto;
                            padding: 0;
                            text-align: center;
                        }
                    }

                    /* CARD BODY */
                    >div.card-body {

                        >form {

                            // Footer
                            >div.card-footer {
                                margin-top: 2rem;
                                display: flex;
                                flex-flow: row wrap;
                                justify-content: space-between;

                                >a,
                                button {
                                    margin-top: .5rem;
                                }

                                &::after {
                                    content: none;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
