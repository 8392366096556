/* ---------------------- */
/* INSCRIPTIONS - GESTION */
/* ---------------------- */

div#gestionInscriptions.card {
    >div.card-body {

        >div {

            /* HEADER */
            &.card-header {

                >h2 {
                    text-align: center;
                    margin-top: 1rem;
                }

                >div {

                    &.alert-success,
                    &.alert-danger,
                    &.alert-warning {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.475s;

                        >ul {
                            list-style: none;
                            margin: 0 auto;
                            padding: 0;
                            text-align: center;
                        }
                    }
                }
            }

            /* BODY */
            &.card-body {

                // USERS
                >ul.todo-list {
                    >li {
                        display: flex;
                        flex-flow: row nowrap;
                        margin-bottom: 1.5rem;
                        border-top: 2px solid #e9ecef;

                        &.declined {
                            border: 2px solid red;
                        }

                        >div {

                            // Nom, Prénom, Société
                            &:first-of-type {
                                width: 75%;

                                >div {
                                    >span {
                                        width: 100%;
                                    }
                                }

                                >hr {
                                    margin-top: .5rem;
                                    margin-bottom: .5rem;
                                }
                            }

                            // Icônes accepter/refuser
                            &:last-of-type {
                                width: 25%;
                                display: flex;
                                flex-flow: row wrap;
                                align-items: center;
                                justify-content: space-evenly;

                                >span {
                                    float: right;
                                    cursor: pointer;

                                    &:hover {
                                        >i {
                                            font-weight: 900;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
