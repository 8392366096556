/* --------------- */
/* PAGES - GESTION */
/* --------------- */

div#gestionPages.card {
    >div {

        /* HEADER */
        &.card-header {
            >h2 {
                text-align: center;
                margin-top: 1rem;
            }

            >div.alert-success,
            >div.alert-danger,
            >div.alert-warning {
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.475s;

                >ul {
                    list-style: none;
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                }
            }
        }

        /* BODY */
        &.card-body {
            >ul.todo-list {
                >li {
                    display: flex;
                    flex-flow: row nowrap;
                    border-left-color: #d1d1d1;
                    color: #697582;
                    margin-bottom: 2rem;

                    &:hover {
                        background-color: rgb(238, 238, 238);
                    }

                    >div.icheck-primary.d-inline {
                        text-align: center;

                        // Nom page
                        &:first-of-type {
                            width: 60%;

                            >h3 {
                                color: #697582;
                                font-weight: bold;
                            }
                        }
                    }

                    >a {
                        text-align: center;
                    }

                    >div {

                        >input[type=checkbox],
                        >select {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-down(sm) {
    div#gestionPages.card {
        >div {

            /* BODY */
            &.card-body {
                >ul.todo-list {
                    >li {
                        flex-flow: row wrap;
                    }
                }
            }
        }
    }
}
