/* ------------- */
/* EMAIL - INFOS */
/* ------------- */

div#emailInfos.card {

    >div {

        /* HEADER */
        &.card-header {

            >h2 {
                text-align: center;
                margin-top: 1rem;
            }

            >div {

                &.alert-success,
                &.alert-danger,
                &.alert-warning {
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity 0.475s;

                    >ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }

        /* BODY */
        &.card-body {
            >div {
                margin-bottom: 2.25rem;

                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                    text-decoration: underline;
                }

                &#message {

                    >div {
                        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
                        padding: 1rem;
                        width: fit-content;
                        margin: auto;
                        padding: .5rem;

                        >h4 {
                            font-size: 1.1rem;
                            text-decoration: none;
                            margin-bottom: .75rem;

                            >span {
                                text-decoration: underline;
                            }
                        }

                        >p {
                            display: flex;
                            flex-flow: column wrap;
                            text-align: center;
                            align-items: center;

                            >img {
                                width: 40% !important;
                            }
                        }
                    }
                }

                // Historique destinataires
                &#destinataires {

                    // Bouton historique destinataires
                    >button {
                        background-color: #eee;
                        color: #444;
                        cursor: pointer;
                        padding: 18px;
                        width: 100%;
                        text-align: left;
                        border: none;
                        outline: none;
                        transition: .4s ease-out;

                        &:before {
                            content: '\02795';
                            font-size: 13px;
                            color: #777;
                            margin-right: 5px;
                        }

                        &:hover {
                            background-color: #ccc;
                        }

                        &.active {
                            background-color: #ccc;
                            margin-bottom: 1rem;

                            &:before {
                                content: "\2796";
                            }
                        }
                    }

                    // Historique destinataires
                    >div {
                        padding: 0 18px;
                        background-color: white;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height .4s ease-out;

                        >div.form-group {
                            position: relative;

                            // Icône searchbar
                            >i {
                                cursor: pointer;
                                position: absolute;
                                top: 52%;
                                left: 0;
                                transform: translateY(-50%);
                                padding: 13px 15px 13px 14px;
                            }

                            // Searchbar
                            >input#searchbar {
                                max-width: 100%;
                                padding: 8px 6px 8px 35px;
                            }
                        }

                        // Tableau destinataires
                        >table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-top: 1rem;

                            >thead {
                                text-align: center;

                                >tr {
                                    >th {
                                        padding: .4rem;
                                        font-size: 1.1rem;
                                        cursor: pointer;
                                        border: 1px #3d4852 solid;
                                        background-color: #f1f1f1;

                                        &.sorted.ascending:after {
                                            content: "  \2191";
                                        }

                                        &.sorted.descending:after {
                                            content: " \2193";
                                        }
                                    }
                                }
                            }

                            >tbody {
                                text-align: left;

                                >tr {

                                    &:hover {
                                        background-color: #f1f1f1;
                                    }

                                    >td {
                                        border: 1px #3d4852 solid;
                                        padding: .25rem .25rem .25rem 1rem;
                                    }
                                }
                            }
                        }
                    }
                }

                /* FOOTER */
                &.card-footer {
                    margin-top: 2rem;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;

                    >a,
                    button {
                        margin-top: .5rem;
                    }

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}
