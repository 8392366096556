/* ---------------------------- */
/* TÉLÉCHARGEMENTS - HISTORIQUE */
/* ---------------------------- */

section#historiqueEmail {
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;

    >article.modal-content {
        position: relative;
        background-color: #fefefe;
        margin: 5vh auto;
        padding: 0;
        border: 1px solid #888;
        width: 80%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation-name: animateTopLogin;
        animation-duration: 0.4s;

        >div {
            &.modal-header {
                justify-content: center;
                padding: 2px 16px;

                >h3 {
                    font-weight: bold;
                    font-size: 1.5rem;
                    padding-top: 1rem;
                    padding-bottom: .5rem;
                }

                >i {
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    font-size: 1.5rem;
                    color: #dc3545;
                    cursor: pointer;

                    &:hover {
                        font-weight: normal;
                    }
                }
            }

            &.modal-body {
                padding: 0 24px 24px 24px;

                >p {

                    // Nbr logiciels dl.
                    &:first-of-type {
                        margin-top: 1rem;
                        margin-bottom: 1.25rem;
                    }

                    // Infos table
                    &:nth-of-type(2) {
                        margin-top: .25rem;
                        margin-bottom: .5rem;
                    }
                }

                >div.form-group {
                    position: relative;
                    margin-bottom: .5rem;

                    i {
                        cursor: pointer;
                        position: absolute;
                        top: 47%;
                        transform: translateY(-50%);
                    }

                    >i {

                        // Icône searchbar
                        &:first-of-type {
                            left: 0;
                            padding: 13px 15px 13px 14px;
                        }

                        // Icône Envoi Mail
                        &:last-of-type {
                            right: 0;
                            color: #007bff;
                            margin-right: 15px;
                            transition: transform .5s;

                            &:hover {
                                transform: translate(12.5%, -65%);
                                font-weight: 600;
                            }
                        }
                    }

                    >input#searchbar {
                        padding: 8px 6px 8px 35px;
                        max-width: 75%;
                    }
                }

                // Historique envois emails
                >table {
                    width: 100%;
                    text-align: center;
                    border-collapse: collapse;

                    >thead {

                        >tr {
                            >th {
                                padding: .4rem;
                                font-size: 1.1rem;

                                &:not(:first-of-type) {
                                    cursor: pointer;
                                    border: 1px #3d4852 solid;
                                    background-color: #f1f1f1;

                                    &.sorted.ascending:after {
                                        content: "  \2191";
                                    }

                                    &.sorted.descending:after {
                                        content: " \2193";
                                    }
                                }

                                // Icône
                                &:first-of-type {
                                    width: 5%;
                                }

                                // Date & Heure
                                &:nth-of-type(2) {
                                    width: 25%;
                                }

                                // Objet
                                &:nth-of-type(3) {
                                    width: 55%;
                                }

                                // Pièce(s) Jointe(s)
                                &:nth-of-type(4) {
                                    width: 10%;
                                }

                                // Nbr destinataires
                                &:last-of-type {
                                    width: 5%;
                                }
                            }
                        }
                    }

                    >tbody {
                        >tr {
                            &:hover {
                                background-color: #f1f1f1;
                            }

                            >td {
                                border: 1px #3d4852 solid;
                                padding: .25rem;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Animation */
    @keyframes animateTopLogin {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}
