/* ------- */
/* SIDEBAR */
/* ------- */

body.sidebar-mini {

    >div.wrapper {

        >aside.main-sidebar {

            /* LOGO */
            >a.brand-link {
                >img {
                    width: 33px;
                }
            }

            /* SIDEBAR */
            >div.sidebar {

                // USER PANEL
                >div.user-panel {
                    >div.info {}
                }

                // MENU
                >nav {
                    >ul.nav.nav-sidebar {
                        >li.nav-item.has-treeview {

                            >a.nav-link {
                                >p {
                                    font-weight: bold;
                                }
                            }

                            // OPEN
                            &.menu-open {
                                background-color: rgba(255, 255, 255, .025);
                            }

                            // GESTION DU SITE
                            &:first-of-type {
                                border-bottom: 1px solid #4f5962;

                                >a.nav-link {}

                                >ul.nav.nav-treeview {
                                    >li.nav-item.has-treeview.li2 {

                                        // MODIFIER CONTENU
                                        &:first-of-type {

                                            // OPEN
                                            &.menu-open {
                                                >ul.nav.nav-treeview {
                                                    >li.nav-item.li3 {
                                                        &:last-of-type {
                                                            >a.nav-link {
                                                                border-bottom: 1px solid #4f5962;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            >a.nav-link {}

                                            >ul.nav.nav-treeview {
                                                >li.nav-item.li3 {
                                                    >a.nav-link {}

                                                    >ul.nav.nav-treeview {
                                                        >li.nav-item.li4 {
                                                            >a.nav-link {}
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // CRÉER CONTENU
                                        &:nth-of-type(2) {

                                            >a.nav-link {}

                                            >ul.nav.nav-treeview {
                                                >li.nav-item.li3 {

                                                    >a.nav-link {}

                                                    >ul.nav.nav-treeview {
                                                        >li.nav-item.li4 {
                                                            >a.nav-link {}
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            // TARIFS
                            &:nth-of-type(2) {
                                border-bottom: 1px solid #4f5962;
                            }

                            // PLANNING
                            &:nth-of-type(3) {
                                border-bottom: 1px solid #4f5962;
                            }

                            // GESTION ADHÉRENTS
                            &:nth-of-type(4) {
                                border-bottom: 1px solid #4f5962;

                                >ul.nav.nav-treeview {
                                    >li.nav-item.li2 {
                                        >a.nav-link {}
                                    }
                                }
                            }

                            // GESTION UTILISATEURS
                            &:nth-of-type(5) {
                                border-bottom: 1px solid #4f5962;

                                >ul.nav.nav-treeview {
                                    >li.nav-item.li2 {
                                        >a.nav-link {}
                                    }
                                }
                            }

                            // OUTILS
                            &:nth-of-type(6) {
                                border-bottom: 1px solid #4f5962;

                                >ul.nav.nav-treeview {
                                    >li.nav-item.li2 {
                                        >a.nav-link {}

                                        >ul.nav.nav-treeview {
                                            >li.nav-item.li3 {
                                                >a.nav-link {}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

/* MOBILE */
@media (max-width: 575px) {
    body.sidebar-mini {
        width: 100vw;

        >div.wrapper {

            /* SIDEBAR */
            >aside.main-sidebar.nav-mobile {
                width: auto;
                position: static;
            }

            /* BODY */
            >div.content-wrapper {
                margin-left: 0;

                nav#nav-mobile {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }

        >footer.main-footer {
            margin-left: 0;
        }
    }
}
