/* ----------- */
/* BACK-OFFICE */
/* ----------- */

/* TOOLS */
/* ----- */

// Bootstrap
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "~bootstrap-fileinput/css/fileinput.min.css";

// Admin-LTE
@import "~admin-lte/dist/css/adminlte.min.css";
@import "~admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
@import "~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
@import "~admin-lte/plugins/daterangepicker/daterangepicker.css";
@import "~admin-lte//plugins/summernote/summernote-bs4.min.css";

// Timepicker
@import "~jquery-timepicker/jquery.timepicker.css";

// Popup (jquery-confirm) :
@import '~jquery-confirm/css/jquery-confirm.css';

// Variables
@import "./variables";

// CSS Permanent
@import "./permanent";


/* COMPONENTS */
/* ---------- */

// Header
@import "./components/header";

// Sidebar
@import "./components/sidebar";

// Dashboard
@import "./components/dashboard";

// Historique 'page client' : téléchargements & emails
@import "./components/historiqueDl";
@import "./components/historiqueEmail";


/* PAGES */
/* ----- */

// Login
@import "./pages/login/show";

// Register
@import "./pages/register/show";

// Profile
@import "./pages/profile/show";

// Pages
@import "./pages/pages/gestion";
@import "./pages/pages/update";

// Fabricants
@import "./pages/fabricants/gestion";
@import "./pages/fabricants/create";
@import "./pages/fabricants/update";

// Logiciels
@import "./pages/logiciels/gestion";
@import "./pages/logiciels/update";

// Clients
@import "./pages/utilisateurs/clients/listeClients";
@import "./pages/utilisateurs/clients/client";

// Inscriptions
@import "./pages/inscriptions/gestion";

// Historique
@import "./pages/historique/gestion";

// Email
@import "./pages/email/email";
@import "./pages/email/clients";
@import "./pages/email/historique";
@import "./pages/email/infos";
