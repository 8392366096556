/* ------------------- */
/* EMAILS - HISTORIQUE */
/* ------------------- */

div#emailsHistorique.card {

    >div {

        /* HEADER */
        &.card-header {

            >h2 {
                text-align: center;
                margin-top: 1rem;
            }

            >div {

                &.alert-success,
                &.alert-danger,
                &.alert-warning {
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity 0.475s;

                    >ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }

        /* BODY */
        &.card-body {

            // Searchbar
            >div.form-group {
                position: relative;

                >i {
                    cursor: pointer;
                    position: absolute;
                    top: 47%;
                    left: 0;
                    transform: translateY(-50%);
                    padding: 13px 15px 13px 14px;
                }

                >input#searchbar {
                    max-width: 100%;
                    padding: 8px 6px 8px 35px;
                }
            }

            // Historique téléchargements
            >table {
                width: 100%;
                text-align: center;
                border-collapse: collapse;

                >thead {

                    >tr {
                        >th {
                            padding: .4rem;
                            font-size: 1.1rem;

                            &:not(:first-of-type) {
                                cursor: pointer;
                                border: 1px #3d4852 solid;
                                background-color: #f1f1f1;

                                &.sorted.ascending:after {
                                    content: "  \2191";
                                }

                                &.sorted.descending:after {
                                    content: " \2193";
                                }
                            }

                            // Icône
                            &:first-of-type {
                                width: 5%;
                            }

                            // Date & Heure
                            &:nth-of-type(2) {
                                width: 25%;
                            }

                            // Objet
                            &:nth-of-type(3) {
                                width: 55%;
                            }

                            // Pièce(s) Jointe(s)
                            &:nth-of-type(4) {
                                width: 10%;
                            }

                            // Nbr destinataires
                            &:last-of-type {
                                width: 5%;
                            }
                        }
                    }
                }

                >tbody {
                    >tr {
                        &:hover {
                            background-color: #f1f1f1;
                        }

                        >td {
                            border: 1px #3d4852 solid;
                            padding: .25rem;
                        }
                    }
                }
            }

            /* FOOTER */
            &.card-footer {
                margin-top: 2rem;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                >a,
                button {
                    margin-top: .5rem;
                }

                &::after {
                    content: none;
                }
            }
        }
    }
}
