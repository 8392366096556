/* ----- */
/* LOGIN */
/* ----- */

div#login.card {
    >div {
        >div {
            >div {

                &:first-of-type {

                    /* LOGO */
                    >div.container-logo {
                        margin-top: 2rem;
                        margin-bottom: 1rem;
                        margin-right: 1rem;
                        margin-left: 1rem;
                        text-align: center;

                        >a {
                            >img {
                                width: 14rem;
                            }
                        }
                    }
                }

                &:nth-of-type(2) {
                    >div {
                        >form {
                            >div.form-group {

                                // Icon show/hidden pwd
                                >i {
                                    float: right;
                                    cursor: pointer;
                                    margin-top: -27px;
                                    margin-right: 10px;
                                    color: #212529a1;

                                    &:hover {
                                        color: #212529;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
