/* ----------------------- */
/* BACK-OFFICE - PERMANENT */
/* ----------------------- */

html {
    scroll-behavior: smooth;

    // TOOLS

    .hidden {
        display: none !important;
    }

    // Message 'vous n'avez pas les droits pour accéder à ce formulaire'.
    p.norights {
        margin: 0 auto;
    }

    // Cache des divs de tooltips SummerNote apparaissant sous le footer.
    div.ui-tooltip {
        display: none;
    }

    // Force le display des feedbacks
    div.invalid-feedback {
        display: block !important;
    }

    // Liste ciblée par lien
    ul.targeted {
        border: 1px solid black;
    }

    // Alert
    div.alert {
        cursor: pointer;
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(xs) {

    /* Media pour laisser le menu visible même en mobile */
    body:not(.sidebar-mini-md) .content-wrapper,
    body:not(.sidebar-mini-md) .main-footer,
    body:not(.sidebar-mini-md) .main-header {
        transition: margin-left .3s ease-in-out;
        margin-left: 250px;
    }

    .main-sidebar,
    .main-sidebar::before {
        margin-left: 0;
    }

}
