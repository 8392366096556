/* -------------- */
/* PAGES - UPDATE */
/* -------------- */

div#updatePages.card {

    /* BODY */
    >div.card-body {

        /* HEADER */
        >div.card-header {
            text-align: center;

            >h2 {
                font-size: 1.5rem;
            }

            >div {

                &.alert-success,
                &.alert-warning,
                &.alert-danger {
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity 0.475s;

                    >ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }

        /* FORMULAIRE */
        >form {

            >fieldset {
                padding-top: 1rem;
                padding-bottom: 1rem;

                // Meta-description, Metakeywords
                &#metadescription,
                &#metakeywords {
                    div.form-group {
                        >label {
                            >i {
                                font-size: .725rem;
                                vertical-align: top;
                                cursor: pointer;

                                &:hover~span,
                                &:focus~span,
                                &:active~span {
                                    opacity: 1;
                                    transform: scale(1) rotate(0);
                                }
                            }

                            >span {
                                position: absolute;
                                z-index: 1000;
                                opacity: 0;
                                font-size: .75rem;
                                color: white;
                                background: rgba(0, 0, 0, .9);
                                padding: .5rem;
                                border-radius: 3px;
                                box-shadow: 0 0 2px rgba(0, 0, 0, .5);
                                transform: scale(0) rotate(-12deg);
                                transition: all .25s;

                                &.infos {
                                    >span {
                                        text-decoration: underline;
                                        font-size: .8rem;
                                        font-weight: bold;
                                    }

                                    >ul {
                                        padding-left: 1rem;
                                        margin-bottom: 0;

                                        >li>ul {
                                            padding-left: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Blocs
                &#blocs {
                    >label {
                        >i {
                            font-size: .725rem;
                            vertical-align: top;
                            cursor: pointer;

                            &:hover~span,
                            &:focus~span,
                            &:active~span {
                                opacity: 1;
                                transform: scale(1) rotate(0);
                            }
                        }

                        >span {
                            position: absolute;
                            z-index: 1000;
                            opacity: 0;
                            font-size: .75rem;
                            color: white;
                            background: rgba(0, 0, 0, .9);
                            padding: .5rem;
                            border-radius: 3px;
                            box-shadow: 0 0 2px rgba(0, 0, 0, .5);
                            transform: scale(0) rotate(-12deg);
                            transition: all .25s;

                            &.infos {
                                >span {
                                    text-decoration: underline;
                                    font-size: .8rem;
                                    font-weight: bold;
                                }

                                >ul {
                                    padding-left: 1rem;
                                    margin-bottom: 0;

                                    >li>ul {
                                        padding-left: 1rem;
                                    }
                                }
                            }
                        }
                    }

                    >fieldset {
                        padding: 0 1rem;

                        >div.form-group {
                            >div.control {
                                display: flex;
                                flex-flow: row nowrap;

                                >span {
                                    padding: .375rem 0;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }

                &#bannieres {}

                &#imgFond {

                    // Insertion Fond
                    >fieldset#fieldImgFond {
                        padding: .05rem 1rem .75rem 1rem;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;

                        >div.row.card {
                            >h4.card-header {
                                font-size: 1.25rem;
                                padding-top: .25rem;
                                padding-bottom: .25rem;
                            }

                            >div.card-body {
                                >div.form-group {

                                    // Insertion de l'image (file-loading)
                                    >div.file-input {
                                        >div.input-group {
                                            >div.file-caption {
                                                font-size: .9rem;
                                                padding: .25rem .5rem;
                                                height: 2rem;
                                            }

                                            >div.input-group-btn {
                                                >button[type=button] {
                                                    padding: .1rem .75rem;
                                                    font-size: .85rem;
                                                    cursor: pointer;
                                                }

                                                >div.btn-file {
                                                    padding: 0.1rem 0.5rem;
                                                    font-size: 0.85rem;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;

                                                    >input[type=file] {}
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Icône corbeille
                    span.deleteImg {
                        >i.fa-trash {
                            color: #dc3545;
                            cursor: pointer;

                            &:hover {
                                color: #c82333;
                            }
                        }
                    }
                }
            }

            div.form-group {
                margin-bottom: 0;

                >input[type=checkbox],
                >input[type=checkbox]~label,
                >input[type=color],
                >select {
                    cursor: pointer;
                }

                >div.control {

                    // UPDATE - BANNIÈRES & FOND
                    >ul.todo-list {
                        >li.images {

                            &:not(:last-of-type) {
                                margin-bottom: .5rem;
                            }

                            >img {
                                width: 100px;
                                margin-bottom: .5rem;
                            }

                            >span.deleteImg,
                            >a {
                                >i {
                                    margin-left: .5rem;
                                    cursor: pointer;
                                }
                            }

                            /* Field update image(s) */
                            // (file-loading devient file-input une fois le <a> clické
                            >div.form-group {
                                padding: .05rem 1rem .75rem 1rem;
                                border-top-left-radius: 0;
                                border-top-right-radius: 0;

                                >a.cancelUpdateImg {
                                    z-index: 10;
                                    width: fit-content;
                                    position: absolute;
                                    right: 0;

                                    >i {
                                        cursor: pointer;

                                        &:hover {
                                            color: #dc3545;
                                        }
                                    }
                                }

                                >h4.card-header {
                                    font-size: 1.05rem;
                                    padding-top: .75rem;
                                    padding-left: 0;
                                    padding-bottom: .5rem;
                                }

                                // Insertion de l'image (file-loading)
                                >div.file-input {
                                    >div.input-group {
                                        >div.file-caption {
                                            font-size: .9rem;
                                            padding: .25rem .5rem;
                                            height: 2rem;
                                        }

                                        >div.input-group-btn {
                                            >button[type=button] {
                                                padding: .1rem .75rem;
                                                font-size: .85rem;
                                                cursor: pointer;
                                            }

                                            >div.btn-file {
                                                padding: 0.1rem 0.5rem;
                                                font-size: 0.85rem;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;

                                                >input[type=file] {}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Footer
            >div.card-footer {
                margin-top: 2rem;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                >a,
                button {
                    margin-top: .5rem;
                }

                &::after {
                    content: none;
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(lg) {
    div#updatePages.card {

        /* BODY */
        >div.card-body {

            /* FORMULAIRE */
            >form {
                div.form-group {

                    // Diaporama
                    >div.control {
                        >ul.todo-list {
                            >li.images {

                                // Field update bannière
                                // (file-loading devient file-input une fois le <a> clické
                                >div.form-group {
                                    >div.file-input {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    div#updatePages.card {

        /* BODY */
        >div.card-body {

            /* FORMULAIRE */
            >form {
                div.form-group {

                    // Diaporama
                    >div.control {
                        >ul.todo-list {
                            >li.images {

                                // Field update bannière
                                // (file-loading devient file-input une fois le <a> clické
                                >div.form-group {
                                    >div.file-input {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
