/* ------------------------ */
/* EMAILS CLIENTS - GESTION */
/* ------------------------ */

div#emailsClients.card {

    >div {

        /* HEADER */
        &.card-header {

            >h2 {
                text-align: center;
                margin-top: 1rem;
            }
        }

        /* BODY */
        &.card-body {

            // Searchbar
            >div.form-group {
                position: relative;

                >i {
                    cursor: pointer;
                    position: absolute;
                    top: 47%;
                    left: 0;
                    transform: translateY(-50%);
                    padding: 13px 15px 13px 14px;
                }

                >input#searchbar {
                    max-width: 66.66%;
                    padding: 8px 6px 8px 35px;
                }
            }

            // Liste clients
            >table {
                width: 100%;
                text-align: center;
                border-collapse: collapse;

                >thead {

                    >tr {
                        >th {
                            padding: .4rem;
                            font-size: 1.1rem;
                            width: 33.33%;
                            cursor: pointer;
                            border: 1px #3d4852 solid;
                            background-color: #f1f1f1;

                            &.sorted.ascending:after {
                                content: "  \2191";
                            }

                            &.sorted.descending:after {
                                content: " \2193";
                            }
                        }
                    }
                }

                >tbody {
                    >tr {
                        &:hover {
                            background-color: #f1f1f1;
                        }

                        >td {
                            border: 1px #3d4852 solid;
                            padding: .25rem;

                            // Icône client
                            &:first-of-type {
                                >a {
                                    vertical-align: middle;
                                    font-size: larger;
                                    line-height: 1rem;

                                    >i {
                                        font-size: larger;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
