/* ----------------- */
/* LOGICIEL - UPDATE */
/* ----------------- */

div#updateLogiciel.card {

    // Progress bar.
    >div.loading-content {
        z-index: 999999;
        position: absolute;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        left: 0;
        margin-bottom: 5rem;

        >p.loading-content {
            margin: 0 auto;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 1rem;
            color: #fff;
        }

        >div.progress {
            position: relative;
            width: 100%;
            height: 1.5rem;
            border: 1px solid #7F98B2;
            padding: 1px;
            border-radius: 3px;

            >div.percent {
                position: absolute;
                display: inline-block;
                top: 10px;
                left: 48%;
                font-size: 1.25rem;
                color: #7F98B2;
            }
        }
    }

    /* FORMULAIRE */
    >div.card-body {

        /* HEADER */
        >div.card-header {
            text-align: center;

            >h2 {
                font-size: 1.5rem;
            }

            >div {

                &.alert-success,
                &.alert-warning,
                &.alert-danger {
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity 0.475s;

                    >ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }

        /* FORMULAIRE */
        >form {

            >div.flex {
                display: flex;
            }

            >fieldset {
                padding-top: 1rem;
                padding-bottom: 1rem;

                // Nom, Version, Description
                &:first-of-type,
                &:nth-of-type(2),
                &:nth-of-type(5) {
                    div.form-group {
                        >label {
                            >i {
                                font-size: .725rem;
                                vertical-align: top;
                                cursor: pointer;

                                &:hover~span,
                                &:focus~span,
                                &:active~span {
                                    opacity: 1;
                                    transform: scale(1) rotate(0);
                                }
                            }

                            >span {
                                position: absolute;
                                z-index: 1000;
                                opacity: 0;
                                font-size: .75rem;
                                color: white;
                                background: rgba(0, 0, 0, .9);
                                padding: .5rem;
                                border-radius: 3px;
                                box-shadow: 0 0 2px rgba(0, 0, 0, .5);
                                transform: scale(0) rotate(-12deg);
                                transition: all .25s;

                                &.infos {
                                    >span {
                                        text-decoration: underline;
                                        font-size: .8rem;
                                        font-weight: bold;
                                    }

                                    >ul {
                                        padding-left: 1rem;
                                        margin-bottom: 0;

                                        >li>ul {
                                            padding-left: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div.form-group {
                margin-bottom: 0;

                >input[type=checkbox],
                >input[type=checkbox]~label,
                >select {
                    cursor: pointer;
                }

                >div.control {

                    // UPDATE FICHIER/LOGO
                    >ul.todo-list {
                        >li.images {

                            &:not(:last-of-type) {
                                margin-bottom: .5rem;
                            }

                            >p {
                                display: inline;
                            }

                            >a {
                                >i {
                                    margin-left: .5rem;
                                    cursor: pointer;
                                }
                            }

                            >i {
                                margin-left: 2.5rem;
                                cursor: pointer;
                            }

                            /* Field update fichier */
                            // (file-loading devient file-input une fois le <a> clické.
                            >div.form-group {
                                padding: .05rem 1rem .75rem 1rem;
                                border-top-left-radius: 0;
                                border-top-right-radius: 0;

                                >a.cancelUpdate {
                                    z-index: 10;
                                    width: fit-content;
                                    position: absolute;
                                    right: 0;
                                    color: grey;

                                    >i {
                                        cursor: pointer;

                                        &:hover {
                                            color: #dc3545;
                                        }
                                    }
                                }

                                >h4.card-header {
                                    font-size: 1.05rem;
                                    padding-top: .75rem;
                                    padding-left: 0;
                                    padding-bottom: .5rem;
                                }

                                // Insertion du fichier/image (file-loading)
                                >div.file-input {
                                    >div.input-group {
                                        >div.file-caption {
                                            font-size: .9rem;
                                            padding: .25rem .5rem;
                                            height: 2rem;
                                        }

                                        >div.input-group-btn {
                                            >button[type=button] {
                                                padding: .1rem .75rem;
                                                font-size: .85rem;
                                                cursor: pointer;
                                            }

                                            >div.btn-file {
                                                padding: 0.1rem 0.5rem;
                                                font-size: 0.85rem;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Footers
            >div.card-footer {
                margin-top: 2rem;
                display: flex;
                flex-flow: row wrap;

                // Actions principales
                &:first-of-type {
                    justify-content: space-between;
                }

                // Actions secondaires
                &:last-of-type {
                    background-color: white;
                    border-top: 1px solid rgba(0, 0, 0, 0.125);

                    >a,
                    button {
                        font-size: .85rem;
                        line-height: .85rem;
                        padding: .25rem .45rem;
                        margin-right: .5rem;
                    }
                }

                >a,
                button {
                    margin-top: .5rem;
                }

                &::after {
                    content: none;
                }
            }
        }
    }
}

/* BACKGROUND LOADING ANIMATION */
section#loading {
    &.loading {
        z-index: 999998;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(6px);
    }
}
