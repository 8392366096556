/* ------------- */
/* EMAIL - ENVOI */
/* ------------- */

div#envoiEmail.card {

    // Spinner loading.
    >div.loading-content {
        z-index: 999999;
        position: absolute;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        left: 0;
        margin-bottom: 5rem;

        >p.loading-content {
            margin: 0 auto;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 1rem;
            color: #fff;
        }

        >img.loading-content {
            margin: 0 auto;
            width: 50px;
            height: 50px;
        }
    }

    >form {
        >div {

            &.card-header {
                text-align: center;

                >div.alert-success,
                >div.alert-danger,
                >div.alert-warning {
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity 0.475s;

                    >ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }

                >button {
                    &[type=submit] {
                        right: 0;
                        position: absolute;
                    }
                }
            }

            &#email.card-body {
                >div.form-group {

                    input[type=checkbox] {
                        cursor: pointer;
                    }

                    >label {
                        >i {
                            font-size: .725rem;
                            vertical-align: top;
                            cursor: pointer;

                            &:hover~span,
                            &:focus~span,
                            &:active~span {
                                opacity: 1;
                                transform: scale(1) rotate(0);
                            }
                        }

                        >span {
                            position: absolute;
                            z-index: 1000;
                            opacity: 0;
                            font-size: .75rem;
                            color: white;
                            background: rgba(0, 0, 0, .9);
                            padding: .5rem;
                            border-radius: 3px;
                            box-shadow: 0 0 2px rgba(0, 0, 0, .5);
                            transform: scale(0) rotate(-12deg);
                            transition: all .25s;

                            &.infos {
                                >span {
                                    text-decoration: underline;
                                    font-size: .8rem;
                                    font-weight: bold;
                                }

                                >ul {
                                    padding-left: 1rem;
                                    margin-bottom: 0;

                                    >li>ul {
                                        padding-left: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &#destinataires.card-body {

                >div:first-of-type {
                    text-align: center;
                    cursor: default;

                    >span {
                        &:not(:nth-of-type(2)) {
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }

                            &.active {
                                font-weight: bold;
                            }
                        }
                    }
                }

                // Searchbar
                >div.form-group {
                    position: relative;

                    >i {
                        cursor: pointer;
                        position: absolute;
                        top: 47%;
                        left: 0;
                        transform: translateY(-50%);
                        padding: 13px 15px 13px 14px;
                    }

                    >input#searchbar {
                        max-width: 90%;
                        padding: 8px 6px 8px 35px;
                    }
                }

                // Liste Clients
                >table {
                    width: 100%;
                    text-align: center;
                    border-collapse: collapse;

                    >thead {

                        >tr {
                            >th {
                                padding: .4rem;
                                font-size: 1.1rem;
                                border: 1px #3d4852 solid;
                                background-color: #f1f1f1;

                                &:not(:last-of-type) {
                                    width: 30%;
                                    cursor: pointer;

                                    &.sorted.ascending:after {
                                        content: "  \2191";
                                    }

                                    &.sorted.descending:after {
                                        content: " \2193";
                                    }
                                }

                                &:last-of-type {
                                    >input[type=checkbox] {
                                        cursor: pointer;

                                        &:disabled {
                                            cursor: not-allowed;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    >tbody {
                        >tr {

                            &:hover {
                                background-color: #f1f1f1;
                            }

                            >td {
                                border: 1px #3d4852 solid;
                                padding: .25rem;

                                &:last-of-type {
                                    >input[type=checkbox] {
                                        cursor: pointer;
                                        transform: scale(1.5);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* BACKGROUND LOADING ANIMATION */
section#loading {
    &.loading {
        z-index: 999998;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(6px);
    }
}
