/* ------------------ */
/* LOGICIEL - GESTION */
/* ------------------ */

div#gestionLogiciels.card {
    >div {

        /* HEADER */
        &.card-header {

            // Titre page
            >h2 {
                text-align: center;
                margin-top: 1rem;
            }

            // Breadcrumb
            >ul {
                display: flex;
                flex-flow: row wrap;
                list-style: none;
                justify-content: center;

                >li {
                    margin-top: .25rem;
                    margin-bottom: .25rem;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &:not(:last-of-type) {
                        padding-right: .25rem;
                        border-right: 1px solid #d1d1d1;
                    }

                    &:not(:first-of-type) {
                        padding-left: .25rem;
                    }
                }
            }

            >div.alert-success,
            >div.alert-danger,
            >div.alert-warning {
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.475s;

                >ul {
                    list-style: none;
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                }
            }
        }

        /* BODY */
        &.card-body {

            // Header-footer
            >div.card-footer {
                display: flex;
                flex-flow: row-reverse wrap;
            }

            >div.card-body {

                // TYPE
                >h2 {
                    text-decoration: underline;
                }

                // FABRICANT
                >ul.todo-list {

                    &:not(:last-of-type) {
                        margin-bottom: 1.5rem;
                    }

                    >li.dropzone {
                        border-left: 2px solid #d1d1d1;
                        color: #697582;

                        input[type=checkbox] {
                            cursor: pointer;
                        }

                        // Messages
                        >div.alert-success,
                        >div.alert-danger,
                        >div.alert-warning {
                            cursor: pointer;
                            opacity: 1;
                            transition: opacity 0.475s;

                            >ul {
                                list-style: none;
                                margin: 0 auto;
                                padding: 0;
                                text-align: center;
                            }
                        }

                        >div:not(.alert) {
                            display: flex;
                            flex-flow: row wrap;

                            // Footer, ajout logiciel
                            &.card-footer {
                                flex-flow: row-reverse nowrap;
                            }

                            >div.icheck-primary.d-inline {
                                line-height: 2.3;

                                // Nom logiciel
                                &:first-of-type {
                                    width: 33%;
                                    color: #495057;

                                    >h3 {
                                        font-weight: bold;
                                        margin-bottom: 0;
                                        text-decoration: none;
                                        >a {
                                            color: inherit;
                                        }
                                    }
                                }

                                >a {
                                    margin-right: 1rem;
                                }

                                >label {
                                    margin-bottom: 0;
                                    margin-right: 1rem;
                                }

                                >span {
                                    margin-right: 1rem;

                                    // Corbeille
                                    &.delete {
                                        cursor: pointer;
                                        color: red;
                                        float: right;
                                    }
                                }
                            }
                        }

                        // LOGICIEL
                        >ul.todo-list {
                            color: #495057;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            border: 1px solid #e9ecef;

                            >div.alert-success {
                                cursor: pointer;
                                opacity: 1;
                                transition: opacity 0.475s;

                                >ul {
                                    list-style: none;
                                    margin: 0 auto;
                                    padding: 0;
                                    text-align: center;
                                }
                            }

                            >li {
                                display: flex;
                                justify-content: space-between;
                                color: #495057;
                                opacity: 1;

                                &:hover {
                                    background-color: rgb(238, 238, 238);
                                }

                                // Icône 'draggable'
                                >span.handle {
                                    margin-right: 1rem;
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    margin-left: 0;
                                    color: #495057;
                                }

                                >div.icheck-primary.d-inline {
                                    // Nom & Version logiciel
                                    &.name {
                                        width: 45%;

                                        >h4 {
                                            text-decoration: none;
                                            font-size: 1rem;
                                            font-weight: normal;
                                            margin-bottom: 0;
                                            margin-right: 1rem;
                                            color: #495057;

                                            >a {
                                                color: inherit;
                                            }
                                        }
                                    }

                                    >i {
                                        cursor: pointer;
                                    }

                                    >span {

                                        // Corbeille
                                        &.delete {
                                            cursor: pointer;
                                            color: red;
                                        }

                                        // Publier
                                        >b {
                                            &.publier {
                                                color: #28a745;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            >div.alert-success {
                margin-bottom: 3rem;
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.475s;

                >ul {
                    list-style: none;
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                }
            }
        }
    }
}
