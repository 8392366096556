/* ------ */
/* HEADER */
/* ------ */

header.content-header {
    >div.container-fluid {
        >div.row {
            justify-content: center;

            >h1 {
                font-weight: bolder;
                text-decoration: underline;
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

/* MOBILE */
@media (max-width: 575px) {
    header.content-header {
        >div.container-fluid {
            >div.row {
                >h1 {
                    font-size: 1.6rem;
                }
            }
        }
    }
}
