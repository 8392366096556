/* ------------------- */
/* FABRICANT - GESTION */
/* ------------------- */

div#gestionFabricants.card {
    >div {

        /* HEADER */
        &.card-header {

            >h2 {
                text-align: center;
                margin-top: 1rem;
            }

            // Breadcrumb
            >ul {
                display: flex;
                flex-flow: row wrap;
                list-style: none;
                justify-content: center;

                >li {
                    margin-top: .25rem;
                    margin-bottom: .25rem;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &:not(:last-of-type) {
                        padding-right: .25rem;
                        border-right: 1px solid #d1d1d1;
                    }

                    &:not(:first-of-type) {
                        padding-left: .25rem;
                    }
                }
            }

            >div.alert-success,
            >div.alert-danger,
            >div.alert-warning {
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.475s;

                >ul {
                    list-style: none;
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                }
            }
        }

        /* BODY */
        &.card-body {

            // Msg confirmation.
            >div.alert-success {
                margin-bottom: 3rem;
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.475s;

                >ul {
                    list-style: none;
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                }
            }

            >div.card-body.dropzone {

                >h2 {
                    text-decoration: underline;
                }

                // FABRICANTS
                >ul.todo-list {

                    // Msg succès suppression.
                    >div.alert-success {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.475s;

                        >ul {
                            list-style: none;
                            margin: 0 auto;
                            padding: 0;
                            text-align: center;
                        }
                    }

                    >li {
                        >ul.todo-list {
                            >li {
                                border-left: 2px solid #d1d1d1;
                                color: #697582;
                                opacity: .3;

                                // Style case cochée.
                                &.done {
                                    color: #495057;
                                    opacity: 1;

                                    >div {
                                        >div.icheck-primary.d-inline {

                                            &.name {
                                                // Nom fabricants.
                                                >h3.text {
                                                    text-decoration: none;
                                                    color: #495057;
                                                }
                                            }
                                        }
                                    }
                                }

                                input[type=checkbox] {
                                    cursor: pointer;
                                }

                                >div {
                                    display: flex;
                                    flex-flow: row wrap;
                                    justify-content: space-between;

                                    >div {
                                        &.logo {
                                            width: 10%;

                                            >img {
                                                width: 100px;
                                                max-height: 50px;
                                            }
                                        }

                                        &.icheck-primary.d-inline {
                                            line-height: 2.3;

                                            &.name {
                                                width: 40%;

                                                // Nom fabricants
                                                >h3.text {
                                                    text-decoration: line-through;
                                                    font-weight: normal;
                                                    margin-bottom: 0;
                                                    margin-right: 1rem;
                                                    color: #697582;
                                                }
                                            }

                                            >a {
                                                margin-right: 1rem;
                                            }

                                            >label {
                                                margin-bottom: 0;
                                                margin-right: 1rem;
                                            }

                                            >span {
                                                margin-right: 1rem;

                                                // Corbeille
                                                &.delete {
                                                    cursor: pointer;
                                                    float: right;
                                                    color: #dc3545;

                                                    &:hover {
                                                        color: #c82333;
                                                    }
                                                }

                                                // Publier
                                                >b {
                                                    &.publier {
                                                        color: #28a745;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            >div.card-footer {
                display: flex;
                flex-flow: row-reverse wrap;
            }
        }
    }
}
