/* ------ */
/* PROFIL */
/* ------ */

div#profile.card {

    /* BODY */
    >div {

        /* NAV */
        >nav {
            display: none;
        }

        >header {
            margin-top: 1rem;
            box-shadow: none !important;

            >div {
                margin-bottom: 1rem;
                text-align: center;
            }
        }

        >main {
            padding-top: 1rem;
            padding-bottom: 2rem;

            >div {
                >div {
                    >div {

                        // Nom, Email, Tel
                        &:first-of-type {
                            box-shadow: none !important;
                            border-top: 1px solid #e9ecef;
                            padding-top: 2rem;
                            padding-bottom: 2rem;

                            >div {

                                // Infos
                                &:first-of-type {
                                    >div {
                                        margin-bottom: 2rem;

                                        >h3 {
                                            font-weight: bold;
                                        }
                                    }
                                }

                                // Formulaire
                                &:last-of-type {
                                    margin-top: 0 !important;

                                    >form {
                                        >div {
                                            padding-top: 0 !important;
                                            padding-bottom: 0 !important;
                                            box-shadow: none !important;

                                            // Inputs
                                            &:first-of-type {
                                                padding-top: 0 !important;
                                                padding-bottom: 0 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Mot de passe
                        &:nth-of-type(3) {
                            box-shadow: none !important;
                            border-top: 1px solid #e9ecef;
                            padding-top: 2rem;
                            padding-bottom: 2rem;

                            >div {

                                >div {

                                    // Infos
                                    &:first-of-type {
                                        >div {
                                            margin-bottom: 2rem;

                                            >h3 {
                                                font-weight: bold;
                                            }
                                        }
                                    }

                                    // Formulaire
                                    &:last-of-type {
                                        margin-top: 0 !important;

                                        >form {
                                            >div {
                                                padding-top: 0 !important;
                                                padding-bottom: 0 !important;
                                                box-shadow: none !important;

                                                >div {

                                                    // Inputs
                                                    &:first-of-type {
                                                        padding-top: 0 !important;
                                                        padding-bottom: 0 !important;

                                                        >div.form-group {

                                                            // Icon show/hidden pwd
                                                            >i {
                                                                float: right;
                                                                cursor: pointer;
                                                                margin-top: -27px;
                                                                margin-right: 10px;
                                                                color: #212529a1;

                                                                &:hover {
                                                                    color: #212529;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Sessions navigateurs
                        &:last-of-type {
                            box-shadow: none !important;
                            border-top: 1px solid #e9ecef;
                            padding-top: 2rem;
                            padding-bottom: 2rem;

                            >div {
                                >div {

                                    // Infos
                                    &:first-of-type {
                                        >div {
                                            >h3 {
                                                font-weight: bold;
                                            }
                                        }
                                    }

                                    // Actions
                                    &:last-of-type {
                                        margin-top: 0 !important;
                                        padding-top: 0 !important;

                                        >div {
                                            padding-top: 0 !important;
                                            padding-bottom: 0 !important;
                                            box-shadow: none !important;

                                            >div {

                                                // Infos
                                                &:first-of-type {}

                                                // Img sessions
                                                &:nth-of-type(2) {
                                                    margin-top: 0.5rem !important;

                                                    >div.flex {
                                                        >div {

                                                            // Img
                                                            &:first-of-type {
                                                                >svg {
                                                                    width: 14rem;
                                                                }
                                                            }

                                                            // Device & Navigateur & IP
                                                            &:last-of-type {}
                                                        }
                                                    }
                                                }

                                                // Submit
                                                &:nth-of-type(3) {
                                                    >button[type=submit] {
                                                        float: right;
                                                    }
                                                }

                                                // Confirmation
                                                &:last-of-type {
                                                    margin-top: 7rem !important;
                                                    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);

                                                    >div {
                                                        &:last-of-type {
                                                            >div {

                                                                // Form
                                                                &:first-of-type {
                                                                    padding-bottom: 0 !important;

                                                                    >div {

                                                                        // Infos
                                                                        &:first-of-type {
                                                                            text-decoration: underline;
                                                                        }

                                                                        // Label + Input
                                                                        &:last-of-type {
                                                                            margin-top: 0 !important;
                                                                        }
                                                                    }
                                                                }

                                                                // Actions
                                                                &:last-of-type {
                                                                    >button {
                                                                        color: #fff !important;
                                                                        box-shadow: none !important;

                                                                        // Cancel
                                                                        &:first-of-type {
                                                                            background-color: #17a2b8 !important;
                                                                            border-color: #17a2b8 !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
