/* ---------------- */
/* CLIENT - GESTION */
/* ---------------- */

div#client.card {

    >div.card-body {
        padding: 0;

        >div {

            /* HEADER */
            &.card-header {

                >h2 {
                    text-align: center;
                    margin-top: 1rem;
                }

                >div {

                    &.alert-success,
                    &.alert-danger,
                    &.alert-warning {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.475s;

                        >ul {
                            list-style: none;
                            margin: 0 auto;
                            padding: 0;
                            text-align: center;
                        }
                    }
                }
            }

            /* BODY */
            &.card-body {
                >div {

                    // Infos
                    &:first-of-type {
                        margin-bottom: 2.25rem;

                        >h3 {
                            font-size: 1.5rem;
                            margin-bottom: 1rem;
                            text-decoration: underline;
                        }

                        >ul {
                            margin-top: 0;
                            list-style: disc;

                            >li {
                                margin: .5rem 0;
                            }
                        }
                    }

                    // Historique téléchargements
                    &:nth-of-type(2) {
                        >h3 {
                            font-size: 1.5rem;
                            margin-bottom: 1rem;
                            text-decoration: underline;
                        }

                        >button {
                            background-color: #eee;
                            color: #444;
                            cursor: pointer;
                            padding: 18px;
                            width: 100%;
                            text-align: left;
                            border: none;
                            outline: none;
                            transition: .4s ease-out;

                            &:before {
                                content: '\02795';
                                font-size: 13px;
                                color: #777;
                                margin-right: 5px;
                            }

                            &:hover {
                                background-color: #ccc;
                            }

                            &.active {
                                background-color: #ccc;
                                margin-bottom: 1rem;

                                &:before {
                                    content: "\2796";
                                }
                            }
                        }

                        >div {
                            padding: 0 18px;
                            background-color: white;
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height .4s ease-out;

                            // Searchbar
                            >div.form-group {
                                position: relative;

                                >i {
                                    cursor: pointer;
                                    position: absolute;
                                    top: 47%;
                                    left: 0;
                                    transform: translateY(-50%);
                                    padding: 13px 15px 13px 14px;
                                }

                                >input#searchbar {
                                    max-width: 75%;
                                    padding: 8px 6px 8px 35px;
                                }
                            }

                            // Tableau historique téléchargements
                            >table {
                                width: 100%;
                                text-align: center;
                                border-collapse: collapse;

                                >thead {
                                    background-color: #f1f1f1;

                                    >tr {
                                        >th {
                                            cursor: pointer;
                                            width: 25%;
                                            padding: .4rem;
                                            border: 1px #3d4852 solid;
                                            font-size: 1.1rem;

                                            &.sorted.ascending:after {
                                                content: "  \2191";
                                            }

                                            &.sorted.descending:after {
                                                content: " \2193";
                                            }
                                        }
                                    }
                                }

                                >tbody {
                                    >tr {
                                        &:hover {
                                            background-color: #f1f1f1;
                                        }

                                        >td {
                                            border: 1px #3d4852 solid;
                                            padding: .25rem;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    /* FOOTER */
                    &.card-footer {
                        margin-top: 2rem;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;

                        >a,
                        button {
                            margin-top: .5rem;
                        }

                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
}
