/* --------- */
/* DASHBOARD */
/* --------- */

section#welcome {
    >article {
        >h2 {
            text-align: center;
        }

        >p {
            text-align: center;
        }
    }
}
